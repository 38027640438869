<script setup lang="ts">
import { useLayout } from '~/composable/layout.js';
import {GROUP_RANGE} from "~/const/ANALYTICS";
import { DotLottie } from '@lottiefiles/dotlottie-web';
import {set} from "@vueuse/core";

const { onMenuToggle } = useLayout();
const authStore = useAuthStore();
const analyticsStore = useAnalyticsStore();
let dotlottie = null;
const showLottie = ref(false);

function signOut () {
  authStore.singOut();
  window.location.reload();
}
onMounted(async () => {
  if (!Object.keys(analyticsStore.shortInfo).length) {
    const defaultSettings = {
      groupRange: GROUP_RANGE.DAY,
      date_from: new Date().toLocaleDateString('en-CA'),
      date_to: new Date().toLocaleDateString('en-CA'),
    };
    const reports = await analyticsStore.getAllReports({...defaultSettings});
    const [total, ...rest] = reports
    analyticsStore.setShortInfo(total)
  };

  dotlottie = new DotLottie({
    canvas: document.querySelector('#dotlottie-canvas'),
    src: "/lottie/pig-money.lottie", // or .json file
  });
  dotlottie.addEventListener("complete", (event) => {
    set(showLottie, false);
    console.log('complete');
  });
});

function startLottie() {
  set(showLottie, true);
  dotlottie.play();
}
</script>

<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </button>
      <router-link to="/" class="layout-topbar-logo hidden md:inline-flex">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100">
          <g fill="#22c55e">
            <path d="M60,50 A40,40 0 1,1 140,50 L100,90 Z"/>
            <circle cx="100" cy="50" r="15"/>
            <path d="M30,50 Q10,50 30,70 Q50,90 70,70" stroke="#22c55e" stroke-width="6" fill="none"/>
            <path d="M170,50 Q190,50 170,70 Q150,90 130,70" stroke="#22c55e" stroke-width="6" fill="none"/>
          </g>
        </svg>

        <span class="md:inline-flex hidden">PUBSERV</span>
      </router-link>
    </div>
    <div class="flex gap-3 md:gap-10 justify-center w-full">
      <Message severity="contrast" v-if="Object.keys(analyticsStore.shortInfo).length" class="cursor-pointer" @click="startLottie">
        <template #icon>
          <Avatar icon="pi pi pi-dollar" shape="circle" style="background-color: transparent;"/>
        </template>
        <span class="ml-1">
          <span class="md:inline-flex hidden">Today Revenue: </span>
          {{ analyticsStore.shortInfo.value.value[0].profit }}
        </span>
      </Message>
      <Message severity="secondary" v-if="Object.keys(analyticsStore.shortInfo).length">
        <template #icon>
          <Avatar icon="pi pi-arrow-up-right" shape="circle" />
        </template>
        <span class="ml-1">
           <span class="md:inline-flex hidden">Today Impressions: </span>
          {{ analyticsStore.shortInfo.value.value[0].impressions }}
        </span>
      </Message>
    </div>



    <div class="layout-topbar-actions">
      <div class="layout-topbar-menu flex gap-5">

        <Message severity="success" class="mr-5 hidden md:inline-flex">{{ authStore.username }}</Message>

        <button type="button" class="layout-topbar-action" @click="signOut" title="Sign-Out">
          <i class="pi pi-sign-out"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="flex fixed top-0 pt-[50px] left-0 right-0 bottom-0 justify-center items-center z-[1000] bg-black/50 transition-all"
       :class="{'invisible opacity-100': !showLottie}">
    <canvas id="dotlottie-canvas" class="h-[400px] md:h-[500px] w-full md:w-[500px]"></canvas>
  </div>
</template>
